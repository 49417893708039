<template>
  <div class="container pb-5">

    <h2 class="pt-4">Om Pyrodok</h2>

    <div class="py-2">
      Pyrodok-appen utvikles av Pyrodok AS.<br>
      Les gjerne mer på <a href="https://pyrodok.no/" target="_blank">nettsiden</a>.
    </div>

    <div class="py-2">
      For å automatisk laste ned siste versjon må appen startes på nytt.<br>
      <b>Obs!</b> Du må da ha en internett-tilkobling.
    </div>

    <div class="py-2">
      App: {{ location }}<br>
      App versjon: {{ this.app.version }}<br> 
      Koblet til: {{ this.app.api.server }}
    </div>

    <div class="pt-4 text-center">
      <router-link class="btn btn-sm btn-primary py-2 px-3 me-3" to="/debug" replace><i class="fas fa-bug"></i>Debug</router-link>
      <button class="btn btn-sm btn-primary py-2 px-3" @click="this.app.restart()"><i class="fas fa-redo"></i>Restart app</button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      location: window.location.host,
    }
  }
}
</script>